const Component = ({ value, onChange }) => {
  const selectStyle = {
    border: "1px solid #dbdfea",
    "border-radius": "5px",
    padding: "5px",
  };

  const currencyOptions = [
    {value: "USDC", label: "USDC"},
    {value: "USDT", label: "USDT"},
    {value: "ETH", label: "ETH"},
  ]

  return (
    <select value={value} onChange={onChange} style={selectStyle}>
      {currencyOptions.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
    </select>
  );
};

export default Component;
