import {Link} from 'react-router-dom';
import { showNumber, showPercent } from '../../libs/util';

const Component = ({plan}) => {

  const getCurrencyName = (currency) => {
    return currency === "USD" ? "US$" : currency
  }

  return (
    <div className="nk-ivp-card card card-bordered card-full">
      <div className="card-inner-group">
        <div className="card-inner">
          <div className="nk-ivp-title card-title">

              <h6 className="data">{plan.display_title}</h6>
              <h1 className="icon-stack">
                  <em className={"bg-dark-dim icon ni ni-sign-"+plan.target+"-alt icon-stack-back"}
                      ></em>
                  <em className={"bg-"+plan.source+"-dim icon ni ni-sign-"+plan.source+"-alt icon-stack-front"}
                      ></em>
              </h1>
              <br/>
                  <span className="data">Invest with {getCurrencyName(plan.source.toUpperCase())}</span>
              <br/>
              <span className="data">
                  <i>{plan.tagline}</i>
              </span>

            {/*<span className="data">High Yield Digital Investment</span>*/}
            {/*<h2 className="title">{plan.target} <em className={"bg-"+plan.currency+"-dim icon ni ni-sign-"+plan.currency+"-alt"}*/}
            {/*        title="The invested amount will be return at the end of term."></em>*/}
            {/*</h2>*/}
            {/*<span className="data">with {plan.source}</span>*/}
          </div>
          <div className="nk-ivp-summary card-text">
            <div className="row">
              <div className="col-12">
                  <span className="sub-text">Annualised Return</span>
                  <span className="lead-text">
                    <span className=" text-dark" id="standard-plan-rate">
                    {plan.minYield === 0 ? "0%" : `${showPercent(plan.minYield)} - ${showPercent(plan.maxYield)}`}
                    </span>
                  </span>
                  {/*<span className="sub-text">Annualised Return</span>*/}
              </div>
              {/*<div className="col-6">*/}
              {/*    <span className="sub-text">&nbsp;</span>*/}
              {/*    <span className="lead-text"><span className="text-dark" id="standard-plan-tenor">{showNumber(plan.maturity)}</span></span>*/}
              {/*    <span className="sub-text">Days</span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="nk-ivp-action">
            <Link className="btn btn-primary" to={"/portal/planscc/invest/" + plan.source + "/" + plan.target}>
              <span>Invest</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component;
