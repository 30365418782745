import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';
import { Errors } from '../../components/common/msg';
import { post, get } from '../../libs/api';

import Plan from '../../components/invest/plan';
import useInterval from "../../libs/useInterval";

function Page() {
  const [isTradeMtn, setIsTradeMtn] = useState(false);
  const [errors, setErrors] = useState([]);
  const [plans, setPlans] = useState([]);

  const checkTradeMtnStatus = async() => {
    const data = await post('/auth/mtn/trade');
    if(data?.data?.trademtn){
      // it is live
      setIsTradeMtn(false);
      setErrors([]);
    } else {
      setIsTradeMtn(true);
      setErrors(['Investment application is unavailable. Please try again later.']);

    }
  }

  const loadPlans = async () => {
    const data = await get('/investment/plan/hydi/list');
    if (data?.status != 200) {
      setErrors([data.error]);
      return;
    }

    let list = data.data;
    for(let item of list){
      if(item.currency == 'btc'){
        item.display_title = 'HYDI BTC/USD-P'
        item.tagline = 'Earn yield on USDC and have a chance to buy BTC lower'
        item.source = 'usdc';
        item.target = 'Bitcoin';
      }
      else if(item.currency == 'eth'){
        item.display_title = 'HYDI ETH/USD-P'
        item.tagline = 'Earn yield on USDC and have a chance to buy ETH lower'
        item.source = 'usdc';
        item.target = 'Ethereum';
      }
      else if(item.currency == 'usd'){
        item.display_title = item.sourceCurrency == 'btc' ? 'HYDI BTC/USD-C' : 'HYDI ETH/USD-C';
        if(item.sourceCurrency == 'btc') {
          item.tagline = 'Earn yield on BTC and have a chance to sell BTC higher'
        } else {
          item.tagline = 'Earn yield on ETH and have a chance to sell ETH higher'
        }
        item.source = item.sourceCurrency == 'btc' ? 'btc' : 'eth';
        item.target = 'US Dollar';
      }
    }
    setPlans(list);
  }

  useEffect(() => {
    loadPlans();

  }, [])

  useInterval(() => {
    checkTradeMtnStatus();
  }, 5000);

  return (
    <Layout menu="plans">
      <div className="nk-content-body">
        <div className="nk-block-head text-center">
          <h2 className="nk-block-title fw-normal">High Yield Dual Investments</h2>
          <div className="nk-block-des w-max-550px m-auto">
            <p className="lead"></p>
            <p><strong>Choose your preferred investment product to earn high returns.</strong></p>
          </div>
        </div>
        <div className="nk-ivp-apps">
            <div className="nk-ivp-plans row g-gs scheme-container justify-center">
              {errors?.length > 0 && (
                <Errors errors={errors}/>
              )}


              {isTradeMtn==false && plans.map((x,i) => (
                <div className="col-md-6 col-lg-6" key={i}>
                  <Plan plan={x}/>
                </div>
              ))}
            </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page;
