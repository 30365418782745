import { getAuthToken } from './auth';

export const post = async(uri, params, redirect = true) => { 
  const token = getAuthToken();
  let headers = {'Content-Type': 'application/json'};
  if(token){
    headers['Authorization'] = 'Bearer ' + token;
  }
  const response = await fetch(getApiUrl() + uri, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(params)
  });
  const data = await response.json();
  if(data?.status == 401 || data?.status == 403){
    if(!window.location.href.includes('/login') && redirect){
      window.location = '/login';
    }
  }
  else if(data?.status == 503) {
    // maintenance
    if(!window.location.href.includes('/maintenance')){
      window.location = '/maintenance';
    }
  }
  return data;
}

export const get = async (uri, redirect = true) => {
  const token = getAuthToken();
  let headers = { 'Content-Type': 'application/json' };
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  const response = await fetch(getApiUrl() + uri, {
    method: 'GET',
    headers: headers
  });
  const data = await response.json();
  if (data?.status === 401 || data?.status === 403) {
    if (!window.location.href.includes('/login') && redirect) {
      window.location = '/login';
    }
  } else if (data?.status === 503) {
    // maintenance
    if (!window.location.href.includes('/maintenance')) {
      window.location = '/maintenance';
    }
  }
  return data;
};

export const download = async(uri, params) => { 
  const token = getAuthToken();
  let headers = {'Content-Type': 'application/json'};
  if(token){
    headers['Authorization'] = 'Bearer ' + token;
  }
  const response = await fetch(getApiUrl() + uri, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(params)
  });
  return response.blob();
}

export const upload = async(uri, file, redirect = true) => { 
  const formData = new FormData();
  formData.append("file", file);

  const token = getAuthToken();
  let headers = {};// {'Content-Type': 'multipart/form-data'};
  if(token){
    headers['Authorization'] = 'Bearer ' + token;
  }
  const response = await fetch(getApiUrl() + uri, {
    method: 'POST',
    headers: headers,
    body: formData
  });
  const data = await response.json();
  if(data?.status == 401 || data?.status == 403){
    if(!window.location.href.includes('/login') && redirect){
      window.location = '/login';
    }
  }
  else if(data?.status == 503) {
    // maintenance
    if(!window.location.href.includes('/maintenance')){
      window.location = '/maintenance';
    }
  }
  return data;
}

export const getApiUrl = () => {
  return process.env.REACT_APP_API_URL;
}
