import {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';
import Card from '../../components/invest/card';
import InvestedPlans from '../../components/invest/investedPlans';
import { post } from '../../libs/api';
import UserContext from "../../libs/context";

function Page() {

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [usdc, setUsdc] = useState(null);
  const [btc, setBtc] = useState(null);
  const [eth, setEth] = useState(null);
  const {notification} = useContext(UserContext);

  const loadAccounts = async() => {
    setLoading(true);
    const data = await post('/wallet/balance', {});
    setLoading(false);
    if(data?.status != 200){
      return;
    }

    setAccounts(data.data);
    for(const wallet of data.data){
      if(wallet.currency == 'USDC'){
        setUsdc(wallet);
      }
      else if(wallet.currency == 'BTC'){
        setBtc(wallet);
      }
      else if(wallet.currency == 'ETH'){
        setEth(wallet);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadAccounts();
  }, [])

  useEffect(() => {
    if(notification?.type == 2) { // order approved
      loadAccounts();
    }
  }, [notification])

  return (
    <Layout menu="investment">
      <UserHead sub="" title="Your Investments" description="">
        <ul className="nk-block-tools gx-3">
          <li className="order-md-last"><Link to="/portal/planscc" className="btn btn-primary"><span>Our Products</span> <em className="icon ni ni-arrow-long-right"></em></Link></li>
          <li><Link to="/portal/deposit" className="btn btn-light btn-white"><span>Deposit</span> <em className="icon ni ni-arrow-long-right"></em></Link></li>
        </ul>
      </UserHead>

      <div className="nk-block">
        <div className="row gy-gs">
          <div className="col-md-4">
            <Card card={usdc}/>
          </div>

          <div className="col-md-4">
            <Card card={btc}/>
          </div>

          <div className="col-md-4">
            <Card card={eth}/>
          </div>
        </div>
      </div>

      <InvestedPlans/>

    </Layout>
  )
}

export default Page;
